.hero-content-area {
  opacity: 0;
  position: absolute;
  animation: slidefade 1s 1s normal forwards;
}

@keyframes slidefade {
  100% {
    opacity: 1;
  }
}

#dashHeader {
  min-height: 100vh;
}

#tsparticles {
  height: 100vh;
  width: 100vw;
}

.Typewriter {
  display: inline;
}

.card {
  height: 100%;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
  filter: invert(100%);
}

.caroImage {
  min-height: 5%;
  max-height: 5%;
  width: auto;
  object-fit: cover;
}
